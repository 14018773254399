import { createSlice } from "@reduxjs/toolkit";
import { getUserProfileApi, getUserStatisticsApi, signInApi, resetPasswordApi, testResetPasswordApi, changePasswordApi, updateUserProfileDataApi, updateUserInformationApi, updateUserProfileImageApi, checkUserExistsApi } from "../../utils/api";
import { apiCallBegan } from "../actions/apiActions";
import { store } from "../store";

// state
const initialState = {
    data: null,
    isLogin: false,
    token: null,
};

// slice
export const userSlice = createSlice({
    name: 'User',
    initialState,
    reducers: {
        loginSuccess: (user, action) => {
            let { data } = action.payload;
            user.data = data;
            user.isLogin = true;
            user.data.userStatics = {};
            user.data.userProfileStatics = {};
            return user;
        },
        resetPasswordSuccess: (user) => {
            return user;
        },
        testResetPasswordSuccess: (user) => {
            return user;
        },
        changePasswordSuccess: (user) => {
            return user;
        },
        checkUserSuccess: (user, action) => {
            let { data } = action.payload;
            user.data = data;
            user.token = data.api_token;
            user.isLogin = false;
            return user;
        },
        logoutSuccess: (user) => {
            user = initialState;
            return user;
        },
        imageUploadSuccess: (user, action) => {
            let { data } = action.payload;
            user.data.profile = data.profile;
        },
        profileUpdateDataSuccess: (user, action) => {
            let data = action.payload;
            user.data.name = data.name;
            user.data.mobile = data.mobile;
        },
        userStatictisDataSuccess: (user, action) => {
            let { data } = action.payload;
            user.data.userStatics = data;
        },
        userprofileStatictisDataSuccess: (user, action) => {
            let { data } = action.payload;
            user.data.userProfileStatics = data;
        },
        updateUserDatainfo: (user, action) => {
            let { data } = action.payload;
            user.data.userProfileStatics.coins = data.coins;
            user.data.userProfileStatics.all_time_score = data.score;
            user.data.coins = data.coins;
        },

    }

});

export const { loginSuccess, resetPasswordSuccess, testResetPasswordSuccess, changePasswordSuccess, checkUserSuccess, logoutSuccess, imageUploadSuccess, profileUpdateDataSuccess, userStatictisDataSuccess, userprofileStatictisDataSuccess, updateUserDatainfo } = userSlice.actions;
export default userSlice.reducer;

// selectors
export const selectUser = (state) => state.User;


// api calls
// check user exists
export const checkUserExist = (email, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...checkUserExistsApi(email),
        displayToast: false,
        onSuccessDispatch: checkUserSuccess.type,
        onStart,
        onSuccess,
        onError
    }))
};

// connexion
export const signIn = (password, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...signInApi(password),
        displayToast: false,
        onSuccessDispatch: loginSuccess.type,
        onStart,
        onSuccess,
        onError
    }))
};

// reset password
export const resetPassword = (email, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...resetPasswordApi(email),
        displayToast: false,
        onSuccessDispatch: resetPasswordSuccess.type,
        onStart,
        onSuccess,
        onError
    }))
};

// Test si reset password
export const testResetPassword = (email, userstate, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...testResetPasswordApi(email, userstate),
        displayToast: false,
        onSuccessDispatch: testResetPasswordSuccess.type,
        onStart,
        onSuccess,
        onError
    }))
};

// Change le password
export const changePassword = (email, password, userstate, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...changePasswordApi(email, password, userstate),
        displayToast: false,
        onSuccessDispatch: changePasswordSuccess.type,
        onStart,
        onSuccess,
        onError
    }))
};

// logout
export const logout = () => {
    store.dispatch(logoutSuccess())
}

// update profile information
export const updateUserInformation = (name, password, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...updateUserInformationApi(name, password),
        displayToast: false,
        onStart,
        onSuccess,
        onError,
    }));
}

// update Image profile
export const updateProfileApi = (image, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...updateUserProfileImageApi(image),
        displayToast: false,
        onSuccessDispatch: imageUploadSuccess.type,
        onStart,
        onSuccess,
        onError,
    }));
}

// update name and mobile
export const updateProfileDataApi = (email, name, mobile, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...updateUserProfileDataApi(email, name, mobile),
        displayToast: false,
        onSuccessDispatch: profileUpdateDataSuccess.type,
        onStart,
        onSuccess,
        onError,
    }))
}

// get user statistics
export const getUserStatisticsDataApi = (onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...getUserStatisticsApi(),
        displayToast: false,
        onSuccessDispatch: userStatictisDataSuccess.type,
        onStart,
        onSuccess,
        onError,
    }))
}

// get user statistics profile
export const getUserProfilestatisticsApi = (id, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...getUserProfileApi(id),
        displayToast: false,
        onSuccessDispatch: userprofileStatictisDataSuccess.type,
        onStart,
        onSuccess,
        onError,
    }))
}

// update user data
export const updateUserDataInfo = (data) => {
    store.dispatch(updateUserDatainfo({ data }));
}