import { createSelector, createSlice } from "@reduxjs/toolkit"
import { store } from "../store";
import { createBattleRoomApi, searchBattleRoomApi, getBattleRoomApi, deleteBattleRoomApi, joinBattleRoomApi, updateBattleRoomApi } from "../../utils/api";
import { apiCallBegan } from "../actions/apiActions";

const initialState = {}

export const groupbattleSlice = createSlice({
    name: "GroupBattle",
    initialState,
    reducers: {
        groupbattleSuccess: (groupbattle, action) => {
            const { key, value } = action.payload;
            switch (key) {
                case 'roomid':
                    groupbattle.roomId = value;
                    break;
                case 'createdby':
                    groupbattle.createdBy = value;
                    break;
                case 'readytoplay':
                    groupbattle.readytoplay = value;
                    break;
                case 'roomCode':
                    groupbattle.roomCode = value;
                    break;
                case 'user1name':
                    groupbattle.user1name = value;
                    break;
                case 'user2name':
                    groupbattle.user2name = value;
                    break;
                case 'user3name':
                    groupbattle.user3name = value;
                    break;
                case 'user4name':
                    groupbattle.user4name = value;
                    break;
                case 'user1image':
                    groupbattle.user1image = value;
                    break;
                case 'user2image':
                    groupbattle.user2image = value;
                    break;
                case 'user3image':
                    groupbattle.user3image = value;
                    break;
                case 'user4image':
                    groupbattle.user4image = value;
                    break;
                case 'user1uid':
                    groupbattle.user1uid = value;
                    break;
                case 'user2uid':
                    groupbattle.user2uid = value;
                    break;
                case 'user3uid':
                    groupbattle.user3uid = value;
                    break;
                case 'user4uid':
                    groupbattle.user4uid = value;
                    break;
                case 'user1CorrectAnswer':
                    groupbattle.user1CorrectAnswer = value;
                    break;
                case 'user2CorrectAnswer':
                    groupbattle.user2CorrectAnswer = value;
                    break;
                case 'user3CorrectAnswer':
                    groupbattle.user3CorrectAnswer = value;
                    break;
                case 'user4CorrectAnswer':
                    groupbattle.user4CorrectAnswer = value;
                    break;
                case 'user1point':
                    groupbattle.user1point = value;
                    break;
                case 'user2point':
                    groupbattle.user2point = value;
                    break;
                case 'entryFee':
                    groupbattle.entryFee = value;
                    break;
                case 'totalusers':
                    groupbattle.totalusers = value;
                    break;
                default:
                    break;
            }
            return groupbattle;
        },
        groupbattleDataClearSuccess: (groupbattle) => {
            groupbattle = initialState;
            return groupbattle;
        },
        createBattleRoomSuccess: (groupbattle, action) => {
            let { data } = action.payload;
            groupbattle.roomId = data;
            return groupbattle;
        },
        searchBattleRoomSuccess: (groupbattle, action) => {
            let { data } = action.payload;
            return groupbattle;
        },
        getBattleRoomSuccess: (groupbattle, action) => {
            let { data } = action.payload;

            if (data) {
                // Info room
                groupbattle.roomId = Number(data.roomId);
                groupbattle.createdBy = Number(data.createdBy);
                groupbattle.readyToPlay = Boolean(Number(data.readyToPlay));
                groupbattle.roomCode = Number(data.roomCode);
                groupbattle.entryFee = Number(data.entryFee);
                groupbattle.totalusers = Number(data.totalusers);

                // Info joueurs
                groupbattle.user1uid = Number(data.user1.uid);
                groupbattle.user1name = data.user1.name;
                groupbattle.user1image = data.user1.profile;
                groupbattle.user1CorrectAnswer = Number(data.user1.correctAnswers);
                groupbattle.user1point = Number(data.user1.points);
                groupbattle.user1answers = data.user1.answers;

                if (data.user2 && data.user2.uid) {
                    groupbattle.user2uid = Number(data.user2.uid);
                    groupbattle.user2name = data.user2.name;
                    groupbattle.user2image = data.user2.profile;
                    groupbattle.user2CorrectAnswer = Number(data.user2.correctAnswers);
                    groupbattle.user2point = Number(data.user2.points);
                    groupbattle.user2answers = data.user2.answers;
                }
                if (data.user3 && data.user3.uid) {
                    groupbattle.user3uid = Number(data.user3.uid);
                    groupbattle.user3name = data.user3.name;
                    groupbattle.user3image = data.user3.profile;
                    groupbattle.user3CorrectAnswer = Number(data.user3.correctAnswers);
                    groupbattle.user3point = Number(data.user3.points);
                    groupbattle.user3answers = data.user3.answers;
                }
                if (data.user4 && data.user4.uid) {
                    groupbattle.user4name = Number(data.user4.uid);
                    groupbattle.user4image = data.user4.name;
                    groupbattle.user4uid = data.user4.profile;
                    groupbattle.user4CorrectAnswer = Number(data.user4.correctAnswers);
                    groupbattle.user4point = Number(data.user4.points);
                    groupbattle.user4answers = data.user4.answers;
                }
            }
            return groupbattle;
        },
        deleteBattleRoomSuccess: (groupbattle, action) => {
            let { data } = action.payload;
            return groupbattle;
        },
        updateBattleRoomSuccess: (groupbattle, action) => {
            let { data } = action.payload;
            return groupbattle;
        },
    }
});

export const { groupbattleSuccess, groupbattleDataClearSuccess, createBattleRoomSuccess, searchBattleRoomSuccess, getBattleRoomSuccess, deleteBattleRoomSuccess, updateBattleRoomSuccess } = groupbattleSlice.actions;
export default groupbattleSlice.reducer;

// load data
export const LoadGroupBattleData = (key, value) => {
    store.dispatch(groupbattleSuccess({ key, value }))
};

// data clear after playing in storage
export const battleDataClear = () => {
    store.dispatch(groupbattleDataClearSuccess())
}

// selector
export const groupbattledata = createSelector(
    state => state.GroupBattle,
    GroupBattle => GroupBattle,
)


/**
 *  Création de la battle room suivant le type
 *  @param int roomType Le type de room qu'on veux créer (1=battleRoom, 2=multiUserBattleRoom)
 *  @param int questionlanguageId L'id de la langue pour le questionnaire
 *  @param int categoryId L'id de la catégorie pour le questionnaire
 *  @param int entryFee Le nombre de coin pour l'entré fee
 *  @param int roomCode Le code d'accès a la room (random)
 *  @param int uid L'id du créateur de la room
 * */
export const createBattleRoom = (roomType, categoryId, entryFee, roomCode, uid, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...createBattleRoomApi(roomType, categoryId, entryFee, roomCode, uid),
        displayToast: false,
        onSuccessDispatch: createBattleRoomSuccess.type,
        onStart,
        onSuccess,
        onError,
    }))
}

/**
 *  Recherche d'une battle room suivant le type
 *  @param int roomType Le type de room qu'on veux chercher (1=battleRoom, 2=multiUserBattleRoom)
 *  @param int categoryId L'id de la catégorie pour le questionnaire
 *  @param int roomCode Le code d'accès a la room (random)
 *  @param int uid L'id de celui qui cherche à joindre la room (doit être différent du créateur)
 * */
export const searchBattleRoom = (roomType, categoryId, roomCode, uid, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...searchBattleRoomApi(roomType, categoryId, roomCode, uid),
        displayToast: false,
        onSuccessDispatch: searchBattleRoomSuccess.type,
        onStart,
        onSuccess,
        onError,
    }))
}

/**
 *  Get une battle room suivant le type
 *  @param int roomId L'id de la room à récupérer
 * */
export const getBattleRoom = (roomId, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...getBattleRoomApi(roomId),
        displayToast: false,
        onSuccessDispatch: getBattleRoomSuccess.type,
        onStart,
        onSuccess,
        onError,
    }))
}

/**
 *  Delete une battle room suivant le type
 *  @param int roomId L'id de la room à delete
 * */
export const deleteBattleRoom = (roomId, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...deleteBattleRoomApi(roomId),
        displayToast: false,
        onSuccessDispatch: deleteBattleRoomSuccess.type,
        onStart,
        onSuccess,
        onError,
    }))
}

/**
 *  Join une battle room avec l'id de la room et l'id user
 *  @param int uid L'id de l'user qui va joindre
 *  @param int userCoins Le nombre de jeton du joueur
 *  @param int roomId L'id de la room à joindre
 * */
export const joinBattleRoom = (uid, roomId, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...joinBattleRoomApi(uid, roomId),
        displayToast: false,
        onSuccessDispatch: getBattleRoomSuccess.type,
        onStart,
        onSuccess,
        onError,
    }))
}

/**
 *  Update une battle room avec l'id de la room
 *  @param int roomId L'id de la room à update
 *  @param json data Les données à update key:value
 * */
export const updateBattleRoom = (roomId, data, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...updateBattleRoomApi(roomId, data),
        displayToast: false,
        onSuccessDispatch: updateBattleRoomSuccess.type,
        onStart,
        onSuccess,
        onError,
    }))
}
